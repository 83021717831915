<script setup>
import { useStore } from "vuex";

const props = defineProps({
  data: [Object, null],
});

const player = computed(() => {
  let info = props.data;
  info.attributes.autoplay = true;
  info.attributes.controls = true;
  return info;
});

const store = useStore();

const handleClose = () => {
  store.dispatch("modals/close", {
    id: "ModalVideo",
  });
};
</script>

<template>
  <div class="modal-video" @keydown.esc="handleClose">
    <div class="modal-video__background" @click="handleClose" />

    <component-common-media
      :classes="['modal-video__wrapper']"
      :media="player"
      :playsinline="true"
      :controls="true"
    />

    <div class="modal-video__ui">
      <button class="modal-video__close-btn" @click="handleClose">
        <ui-icons-close class="modal-video__close-icon" />
      </button>
    </div>
  </div>
</template>

<style>
.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  display: flex;
  flex-direction: column;
  color: #fff;
}

.modal-video .media {
  height: 100%;
  object-fit: contain;
}
.modal-video .video video {
  object-fit: contain;
}

.modal-video.fade-enter-active,
.modal-video.fade-active {
  transition:
    transform 0.8s var(--cubic-ease),
    opacity 0.4s ease;
}

.modal-video.fade-enter-active .modal-video__ui,
.modal-video.fade-active .modal-video__ui {
  opacity: 1;
}

.modal-video.fade-enter-from,
.modal-video.fade-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.modal-video.fade-enter-from .modal-video__ui,
.modal-video.fade-leave-to .modal-video__ui {
  opacity: 0;
}

.modal-video__ui {
  position: absolute;
  top: 0;
  right: 0;
  transition: opacity 1s ease 0.8s;
  z-index: 2;
}

.modal-video__close-btn {
  display: flex;
  align-items: center;
  padding: var(--grid-gap);
  background-color: #000;
}

.modal-video__close-btn svg {
  transform: translateX(-50%);
}

.modal-video__close-icon {
  height: 10px;
  margin-left: 10px;
  width: auto;
}

.modal-video__background {
  position: absolute;
  top: -25%;
  left: -25%;
  width: 150%;
  height: 150%;
  background-color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}

.modal-video__wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: var(--grid-bleed);
  width: calc(100% - var(--grid-bleed) - var(--grid-bleed));
}

.modal-video .video {
  /* Media query adjustment */
}

@media (min-width: 768px) {
  .modal-video .video {
    height: 100%;
  }
}
</style>

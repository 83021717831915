<script setup>
import { useStore } from "vuex";

const store = useStore();

const handleClose = () => {
  store.dispatch("modals/close", {
    id: "ModalCookie",
  });
};

const acceptCookies = () => {
  if (process.client) {
    const win = window;
    const cookieCategory = "cookie_cat_statistic";

    if (win.cicc && cookieCategory in win.cicc) {
      win.cicc[cookieCategory] = true;
      win.CookieInformation.submitConsent();
    }
  }

  handleClose();
};
</script>

<template>
  <div class="modal-cookie" @keydown.esc="handleClose">
    <div class="modal-cookie__banner">
      <span class="modal-cookie__icon"></span>
      <ui-font-text type="l"> You control your data </ui-font-text>
      <ui-font-text type="s">
        You have to consent to statistical cookies to see this video.
        <button class="modal-cookie__link" @click="acceptCookies">
          Accept cookies
        </button>
      </ui-font-text>
    </div>
  </div>
</template>

<style lang="postcss">
.modal-cookie {
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  background: hsla(var(--color-black) / 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;

  &__banner {
    position: relative;
    display: block;
    padding: units(4);
    margin: auto;
    max-width: 328px;
    color: white;
    background-color: var(--ash-grey);

    p {
      margin-top: 1rem;
      line-height: 1.2;
    }
  }

  &__link {
    text-decoration: underline;
    cursor: pointer;
  }

  &__icon {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 16px;
    height: 16px;
    background-position: center center;
    background-size: 16px 16px;
    background-repeat: no-repeat;
  }
}
</style>

<script setup>
import { useStore } from "vuex";

const props = defineProps({
  data: [Object, Array, null],
});

const player = computed(() => {
  console.log(props.data);
  let info = props.data[0] ?? props.data;

  if (info.provider == "TwentyThree") {
    info = {
      ...info,
      code: info.code.replace("autoPlay=0", "autoPlay=1"),
    };
  }

  return info;
});

const store = useStore();

const handleClose = () => {
  store.dispatch("modals/close", {
    id: "ModalEmbed",
  });
};
</script>

<template>
  <div class="modal-embed" @keydown.esc="handleClose">
    <div class="modal-embed__background" @click="handleClose" />

    <component-common-media
      :classes="['modal-embed__wrapper']"
      :media="player"
      :playsinline="true"
    />

    <div class="modal-embed__ui">
      <button class="modal-embed__close-btn" @click="handleClose">
        <ui-icons-close class="modal-embed__close-icon" />
      </button>
    </div>
  </div>
</template>

<style lang="postcss">
.modal-embed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 500;
  display: flex;
  flex-direction: column;
  color: #fff;

  &.fade-enter-active,
  &.fade-active {
    transition:
      transform 0.8s var(--ease-in-cubic),
      opacity 0.4s ease;
    .modal-embed__ui {
      opacity: 1;
    }
  }

  &.fade-enter-from,
  &.fade-leave-to {
    opacity: 0;
    transform: scale(0.9);
    .modal-embed__ui {
      opacity: 0;
    }
  }

  &__ui {
    position: absolute;
    top: 0;
    right: 0;
    transition: opacity 1s ease 0.8s;
    z-index: 2;
  }

  &__close-btn {
    display: flex;
    align-items: center;
    padding: var(--grid-gap);
    background-color: #000;

    svg {
      transform: translateX(-50%);
    }
  }

  &__close-icon {
    height: 10px;
    width: auto;

    margin-left: 10px;
  }

  &__background {
    position: absolute;
    top: -25%;
    left: -25%;
    width: 150%;
    height: 150%;
    background-color: rgba(0, 0, 0, 0.9);
    cursor: pointer;
  }

  &__wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: var(--grid-margin);
    width: calc(100% - var(--grid-margin) - var(--grid-margin));
  }

  .embed-code {
    @media (--md) {
      height: 100%;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);

      > div {
        width: 100% !important;
        height: 100% !important;
        padding-bottom: 0 !important;
      }
    }
  }
}
</style>

<template>
  <div class="layout">
    <slot />
    <component
      v-if="modalsActive"
      :is="components[modalsActive.id]"
      v-bind="{ data: modalsActive.data.data }"
    />
  </div>
</template>

<script setup>
import { useStore } from "vuex";

const store = useStore();
const modalsActive = computed(() => {
  return store.getters["modals/active"];
});
const components = {
  ModalVideo: resolveComponent("component-common-modal-video"),
  ModalEmbed: resolveComponent("component-common-modal-embed"),
  ModalCookie: resolveComponent("component-common-modal-cookie"),
};
</script>
